



























































































































































































































import Layout from "@/router/layouts/main.vue";
import Vue from "vue";
import i18n from "@/i18n/i18n";
export default Vue.extend({
  name: "InvoiceDataFeed",
  components: {
    Layout,

  },
  async mounted() {
    if (this.datafeed.length < 1) {
      await this.fetchDatafeed({ page: 1 });
      this.lc_Datafeed = JSON.parse(JSON.stringify(this.datafeed[0]));
      this.lc_DataFeedPayIn = JSON.parse(JSON.stringify(this.datafeedpayin));
    }
  },
  data() {
    return {
      title: "Invoice Data Feed",
      lc_Datafeed: {},
      lc_DataFeedPayIn: [],
      addBank:false,
      newBank: {
        iban: "",
        swiftCode: "",
        bankName: "",
      },
    };
  },
  methods: {
    toggleBank() {
      this.addBank=!this.addBank
    },
    async updateDF(){
        let {id,...exclude}=this.lc_Datafeed
        await this.updateDatafeed({data:
        {...exclude,invoiceDataFeedSelectedPaymentMethodId:
        JSON.stringify(exclude.invoiceDataFeedSelectedPaymentMethodId)},
        id:id,
        banks:this.lc_DataFeedPayIn})
        await this.fetchDatafeed({ page: 1 });
        this.lc_Datafeed = JSON.parse(JSON.stringify(this.datafeed[0]));
        this.lc_DataFeedPayIn = JSON.parse(JSON.stringify(this.datafeedpayin));

    },
    async addNewBank(){
      await this.createBank({data:this.newBank})
      await this.fetchDatafeed({ page: 1 });
      this.lc_DataFeedPayIn = JSON.parse(JSON.stringify(this.datafeedpayin));

      this.addBank=false


    }
  },
});
